.container {
  align-items: flex-start;
  column-gap: 138px;
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(2, 360px);
  justify-content: center;
  row-gap: 84px;
}

.loadingSpinner {
  margin: auto;
}

.pieChart {
  height: 300px;
  width: 300px;
}

.pieChartLabel {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.pieChartWithLabel {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.streaks {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.streakRow {
  column-gap: 24px;
  display: flex;
  justify-content: space-between;
}

.streakRows {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

@media only screen and (max-width: 1024px) {
  .container {
    column-gap: 90px;
    row-gap: 80px;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    column-gap: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(1, 340px);
    row-gap: 64px;
  }
}