.container {
  align-items: center;
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
}

.left {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

.time {
  flex-shrink: 0;
}

@media only screen and (max-width: 768px) {
  .container, .left {
    column-gap: 12px;
  }
}