/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorDimmer {
  color: var(--color-dimmer);
}

.colorBeigeDark {
  color: var(--color-beigeDark);
}

.colorBeigeLight {
  color: var(--color-beigeLight);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorNavy {
  color: var(--color-navy);
}

.colorNavyHover {
  color: var(--color-navyHover);
}

.colorPink {
  color: var(--color-pink);
}

.colorRust {
  color: var(--color-rust);
}

.colorWinterGreen {
  color: var(--color-winterGreen);
}

.colorWinterGreenHover {
  color: var(--color-winterGreenHover);
}

.colorYellow {
  color: var(--color-yellow);
}

.colorYellowHover {
  color: var(--color-yellowHover);
}

.colorArtistPillShadow {
  color: var(--color-artistPillShadow);
}

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorBrightPurple {
  color: var(--color-brightPurple);
}

.colorCardBackground {
  color: var(--color-cardBackground);
}

.colorClickableAreaBackground {
  color: var(--color-clickableAreaBackground);
}

.colorDarkCerulean {
  color: var(--color-darkCerulean);
}

.colorDarkPurple {
  color: var(--color-darkPurple);
}

.colorError {
  color: var(--color-error);
}

.colorFloatingElement {
  color: var(--color-floatingElement);
}

.colorGreen {
  color: var(--color-green);
}

.colorLightBlue {
  color: var(--color-lightBlue);
}

.colorLightPink {
  color: var(--color-lightPink);
}

.colorLightPurple {
  color: var(--color-lightPurple);
}

.colorLightPurpleHover {
  color: var(--color-lightPurpleHover);
}

.colorLinkWater {
  color: var(--color-linkWater);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorOverlayButton {
  color: var(--color-overlayButton);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorProgressBar {
  color: var(--color-progressBar);
}

.colorPurple {
  color: var(--color-purple);
}

.colorPurpleGradientHover {
  color: var(--color-purpleGradientHover);
}

.colorRed {
  color: var(--color-red);
}

.colorRedHover {
  color: var(--color-redHover);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorShader {
  color: var(--color-shader);
}

.colorSkeletonBase {
  color: var(--color-skeletonBase);
}

.colorSkeletonHighlight {
  color: var(--color-skeletonHighlight);
}

.colorSpotlightOverlay {
  color: var(--color-spotlightOverlay);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}

