/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorArtistPillShadow {
  background-color: var(--color-artistPillShadow);
}

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorBeigeDark {
  background-color: var(--color-beigeDark);
}

.backgroundColorBeigeLight {
  background-color: var(--color-beigeLight);
}

.backgroundColorBrightPurple {
  background-color: var(--color-brightPurple);
}

.backgroundColorCardBackground {
  background-color: var(--color-cardBackground);
}

.backgroundColorClickableAreaBackground {
  background-color: var(--color-clickableAreaBackground);
}

.backgroundColorDarkCerulean {
  background-color: var(--color-darkCerulean);
}

.backgroundColorDarkPurple {
  background-color: var(--color-darkPurple);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorFloatingElement {
  background-color: var(--color-floatingElement);
}

.backgroundColorFooter {
  background: var(--color-footer);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorGreen {
  background-color: var(--color-green);
}

.backgroundColorLightBlue {
  background-color: var(--color-lightBlue);
}

.backgroundColorLightPink {
  background-color: var(--color-lightPink);
}

.backgroundColorLightPurple {
  background-color: var(--color-lightPurple);
}

.backgroundColorLightPurpleHover {
  background-color: var(--color-lightPurpleHover);
}

.backgroundColorLinkWater {
  background-color: var(--color-linkWater);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorNavy {
  background-color: var(--color-navy);
}

.backgroundColorNavyHover {
  background-color: var(--color-navyHover);
}

.backgroundColorOverlayButton {
  background-color: var(--color-overlayButton);
}

.backgroundColorPink {
  background-color: var(--color-pink);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorProgressBar {
  background-color: var(--color-progressBar);
}

.backgroundColorPurple {
  background-color: var(--color-purple);
}

.backgroundColorPurpleGradient {
  background: var(--color-purpleGradient);
}

.backgroundColorPurpleGradientHover {
  background-color: var(--color-purpleGradientHover);
}

.backgroundColorRed {
  background-color: var(--color-red);
}

.backgroundColorRedHover {
  background-color: var(--color-redHover);
}

.backgroundColorRust {
  background-color: var(--color-rust);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorShader {
  background-color: var(--color-shader);
}

.backgroundColorSkeletonBase {
  background-color: var(--color-skeletonBase);
}

.backgroundColorSkeletonHighlight {
  background-color: var(--color-skeletonHighlight);
}

.backgroundColorSpotlightOverlay {
  background-color: var(--color-spotlightOverlay);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

.backgroundColorWinterGreen {
  background-color: var(--color-winterGreen);
}

.backgroundColorWinterGreenHover {
  background-color: var(--color-winterGreenHover);
}

.backgroundColorYellow {
  background-color: var(--color-yellow);
}

.backgroundColorYellowHover {
  background-color: var(--color-yellowHover);
}

