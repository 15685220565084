.container {
  background-color: var(--color-beigeDark);
  border-radius: 48px;
  box-sizing: border-box;
  margin: auto;
  max-width: 940px;
  padding: 52px 84px;
  width: 100%;
}

.loadingSpinner {
  align-self: center;
}

.rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 36px;
  row-gap: 24px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 36px;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 24px;
  }
}