.link {
  text-decoration: none;
}

.tabButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 16px 24px;
  position: relative;
  row-gap: 6px;
}

.tabButtonActive .tabButtonText {
  color: var(--color-brightPurple);
}

.tabButtonActive path {
  stroke: var(--color-brightPurple);
}

@media (hover: hover) {
  .tabButton:hover .tabButtonText {
    color: var(--color-brightPurple);
  }

  .tabButton:hover path {
    stroke: var(--color-brightPurple);
  }
}

@media only screen and (max-width: 640px) {
  .tabButton {
    border-radius: 12px;
    flex-grow: 1;
    padding: 12px 12px 20px;
  }
}
