.wallet-adapter-modal-title {
  font-family: var(--font-family-header2) !important;
  font-size: 24px !important;
  font-weight: var(--font-weight-header2) !important;
  letter-spacing: var(--letter-spacing-header2) !important;
  line-height: var(--line-height-header2) !important;
  text-transform: uppercase !important;

  color: var(--color-navy) !important;
}

.wallet-adapter-modal-wrapper {
  background-color: var(--color-beigeLight) !important;
}

.wallet-adapter-modal-button-close {
  background-color: transparent !important;
}

.wallet-adapter-modal-button-close svg {
  fill: var(--color-navy) !important;
}

.wallet-adapter-button {
  font-family: var(--font-family-body1) !important;
  font-size: var(--font-size-body1) !important;
  font-weight: var(--font-weight-body1) !important;
  letter-spacing: var(--letter-spacing-body1) !important;
  line-height: var(--line-height-body1) !important;

  color: var(--color-navy) !important;
}

.wallet-adapter-button-trigger, .wallet-adapter-button:not([disabled]):hover {
  background-color: var(--color-beigeDark) !important;
}

.wallet-adapter-modal-overlay {
  background-color: var(--color-backgroundOverlay) !important;
}

.wallet-adapter-modal-list-more {
  color: var(--color-navy) !important;
}

.wallet-adapter-modal-list-more svg {
  fill: var(--color-navy) !important;
}

@media only screen and (max-width: 400px) {
  .wallet-adapter-modal-title {
    font-size: 20px !important;
  }
}