.center {
  display: flex;
  justify-content: center;
}

.container {
  padding-top: 48px;
}

.left {
  align-items: center;
  column-gap: 64px;
  display: flex;
  justify-content: flex-start;
}

.right {
  display: flex;
  justify-content: flex-end;
}


.row1 {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.row2 {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

@media only screen and (max-width: 1280px) {
  .container {
    padding-top: 36px;
  }

  .left {
    column-gap: 36px;
  }
}